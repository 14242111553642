<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl mt-5 font-bold text-blue-800">
        WEMA Bank Account Query
      </h1>
      <div class="flex flex-wrap xl:-mx-4 pb-5">
        <div class="w-full xl:my-4 xl:px-4">
          <div
            class="w-full p-5 pb-10 border-2 border-gray-100 bg-white rounded-lg"
          >
            <div>
              <div class="flex flex-wrap xl:-mx-2">
                <div class="w-full xl:my-2 xl:px-2">
                  <!-- Column Content -->
                  <p class="text-xs font-bold mb-3">
                    Enter Business Account Number
                  </p>
                  <p class="text-sm font-semibold inline-block">
                    <input
                      type="text"
                      class="inline-block p-3 ring-1 w-full outline-none ring-gray-300 rounded-sm text-sm"
                      name=""
                      placeholder="account number"
                      id=""
                      v-model="businessAcctNumber"
                      required
                    />
                  </p>
                  <button
                    class="py-3 px-6 bg-blue-800 mx-2 rounded-md font-semibold text-white text-sm"
                    @click="fetchBusinessAccount"
                  >
                    Search
                  </button>
                  <button
                    class="py-3 px-6 bg-red-800 mx-2 rounded-md font-semibold text-white text-sm"
                    @click="clearRecord"
                  >
                    Clear
                  </button>
                </div>
              </div>
              <p v-if="errorMssg" class="mt-3 text-red-600 text-xs">
                {{ errorMssg }}
              </p>
            </div>
          </div>
          <div class="mt-14" style="min-height: 600px" v-if="rows.length > 0">
            <table class="shadow-lg w-full relative border-collapse">
              <thead>
                <tr>
                  <th
                    class="text-black text-sm whitespace-nowrap text-left px-8 py-2"
                  >
                    Originator Account Number
                  </th>
                  <th
                    class="text-black text-sm whitespace-nowrap text-left px-8 py-2"
                  >
                    Amount
                  </th>
                  <th
                    class="text-black text-sm whitespace-nowrap text-left px-8 py-2"
                  >
                    Originator Name
                  </th>
                  <th
                    class="text-black text-sm border whitespace-nowrap text-left px-8 py-2"
                  >
                    Narration
                  </th>
                  <th
                    class="text-black text-sm border whitespace-nowrap text-left px-8 py-2"
                  >
                    CR Account Name
                  </th>
                  <th
                    class="text-black text-sm border whitespace-nowrap text-left px-8 py-2"
                  >
                    Payment Reference
                  </th>
                  <th
                    class="text-black text-sm border whitespace-normal text-left px-8 py-2"
                  >
                    Bank Name
                  </th>
                  <th
                    class="text-black text-sm border whitespace-nowrap text-left px-8 py-2"
                  >
                    Session ID
                  </th>
                  <th
                    class="text-black text-sm border whitespace-nowrap text-left px-8 py-2"
                  >
                    CR Account
                  </th>
                  <th
                    class="text-black text-sm border whitespace-nowrap text-left px-8 py-2"
                  >
                    Bank Code
                  </th>
                  <th
                    class="text-black text-sm border whitespace-nowrap text-left px-8 py-2"
                  >
                    Request Date
                  </th>
                  <th
                    class="text-black text-sm border whitespace-nowrap text-left px-8 py-2"
                  >
                    NIBSS Response
                  </th>
                  <th
                    class="text-black text-sm border whitespace-nowrap text-left px-8 py-2"
                  >
                    Send Status
                  </th>
                  <th
                    class="text-black text-sm border whitespace-nowrap text-left px-8 py-2"
                  >
                    Send Response
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, i) in rows" :key="i">
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.originatoraccountnumber || 'N/A' }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.amount || 'N/A' }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.originatorname || 'N/A' }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.narration || 'N/A' }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.craccountname || 'N/A' }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.paymentreference || 'N/A' }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.bankname || 'N/A' }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.sessionid || 'N/A' }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.craccount || 'N/A' }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.bankcode || 'N/A' }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.requestdate || 'N/A' }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.nibssresponse || 'N/A' }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.sendstatus || 'N/A' }}
                  </td>
                  <td
                    class="border px-8 py-3 break-words whitespace-normal text-xs"
                  >
                    {{ row.sendresponse || 'N/A' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  data() {
    return {
      moment,
      isLoading: false,
      businessAcctNumber: '',
      errorMssg: false,
      successMssg: false,
      promptModal: false,
      rows: [],
    }
  },
  computed: {
    ...mapGetters(['GET_WALLET_BUSINESSES']),
    filterBusinesses() {
      return this.GET_WALLET_BUSINESSES.data
        ? this.GET_WALLET_BUSINESSES.data
        : undefined
    },
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix
      } else {
        return text
      }
    },
  },
  async created() {
    // this.clearRecord();
  },
  methods: {
    handleViewSelected(filterBusinesses) {
      this.clearRecord()
      this.$router.push({
        name: 'NewWalletManagementPortal',
        params: {
          id: filterBusinesses.business_id,
          email: filterBusinesses.email,
          businessName: filterBusinesses.business_name,
          phoneNumber: filterBusinesses.phone_number,
        },
      })
    },
    handleGoBack() {
      this.clearRecord()
      this.$router.push({
        name: 'WalletManagementPortal',
      })
    },
    async fetchBusinessAccount() {
      if (this.businessAcctNumber !== '') {
        let businessAcctNumber = this.businessAcctNumber
        var myHeaders = new Headers()
        myHeaders.append('Content-Type', 'application/json')

        var raw = JSON.stringify({
          craccount: businessAcctNumber,
        })

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
        }

        try {
          this.isLoading = true
          fetch(
            'https://apps3.wemabank.com/FintechTransQuery/api/v1/Trans/TransQuery',
            requestOptions
          )
            .then((response) => response.text())
            .then((result) => {
              //   console.log(result);
              const response = JSON.parse(result)
              this.isLoading = false
              if (response.status !== '00') {
                this.errorMssg = response.status_desc
              } else {
                this.rows = response.transactions
              }
            })
            .catch((error) => console.log('error', error))
        } catch (error) {}
      }
    },
    clearRecord() {
      if (this.businessAcctNumber !== '') {
        this.businessAcctNumber = ''
      }
    },
  },
}
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(222, 230, 242) none repeat scroll 0% 0%;
  /* border-top: 1px solid rgb(0, 9, 61); */
  text-align: left;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
